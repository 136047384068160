import React from 'react';
import {useContext} from 'react';
import {ch, en} from '../util/common';
import LanguageContext from '../context/language.context';
import TopNav from '../TopNav/TopNav';
import BFFooter from '../BFFooter/BFFooter';
import Section from '../Section/Section';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './QuWay.scss';

import quway2 from '../assets/image/quway/1双语点读小百科套装-4.jpg';
import quway3 from '../assets/image/quway/2拼音主图-1.jpg';
import quway4 from '../assets/image/quway/3粤语点读小百科.jpg';
import quway5 from '../assets/image/quway/4.jpg';
import quway6 from '../assets/image/quway/三字经弟子规-1.jpg';
import quway7 from '../assets/image/quway/交通+动物.jpg';
import quway8 from '../assets/image/quway/快乐美食家.jpg';
import quway9 from '../assets/image/quway/我爱欢唱主图1.jpg';
import quway10 from '../assets/image/quway/收银机-4.jpg';
import quway11 from '../assets/image/quway/晚安故事-组合图5.jpg';
import quway12 from '../assets/image/quway/生活+乐器.jpg';
import quway13 from '../assets/image/quway/神奇小汽车-2.jpg';
import quway14 from '../assets/image/quway/精选唐诗主图2.jpg';
import quway15 from '../assets/image/quway/自然拼读-2.jpg';

export default function QuWay() {
  const {language} = useContext(LanguageContext);

  return (
    <div className="quway">
      <TopNav/>
      <div className="logo-title">
        <img className="logo-image" src="images/QuWay.jpg"/> <span className="title-text">{ch(language) ? '公司介紹' : 'Introduction'}</span>
      </div>
      <div className="bf-single-section">
        {en(language) &&
          <>
          Quway was founded in 2011 and focuses on the development of early education toys and books for preschool children aged 0-6. In 2017, the “Parent-Child Fun Family Training” program was launched. Since 2018, Quway has developed products independently and cooperated with Taiwanese education institutions such as the Taiwan Windmill Publishing Group and the Parenting Children World organization. In addition, Quway has established sales alliances with more than 600 distributors.
          </>
        }
        {ch(language) &&
          <>
          趣威文化创立于2011年，专注于开发0-6岁学前幼儿发展所需的早教玩具和图书产品。2017年，推出“亲子趣家庭培训”计划；2018年，品牌已自主研发产品，并与台湾风车出版集团、亲子天下等台湾儿童教育机构合作。2018年，趣威文化与600多家经销商建立销售同盟合作。
          </>
        }
      </div>
      <Section title={ch(language) ? '品牌理念' : 'Brand Concept'}>
        <div className="bf-single-section">
        {en(language) &&
          <>
          Quway products are designed to accompany and support children as they grow. Quway’s development department focuses on stimulating creativity and providing peace of mind and convenience. Based on the core value of helping children learn through play, Quway continuously develops and produces products to inspire children’s intellect while playing. They are committed to consistently improving the quality of their products and customer service.
          <br/>
          Quway aims to assist parents in their self-growth, enhance family relationships, build family foundations, and nurture the talents of children.
          </>
        }
        {ch(language) &&
          <>
          趣威文化致力于陪伴宝宝健康成长，在开发产品中，开发团队秉持启智早教、创意好玩、安心方便的原则，不断进行学前幼儿发展所需的早教玩具和图书产品的研发与生产，趣威文化的核心理念就是玩趣威学习早一步，在提升产品品质和服务质量之时，进一步开拓市场，帮助孩子们在玩乐中启发智力。
          <br/>
          品牌旨在协助父母自我成长，增进亲子关系，致力于打造家庭根基，助力孩子的天赋发展。
          </>
        }
        </div>
      </Section>
      <Section title={ch(language) ? '品牌使命' : 'Mission'}>
        <div className="bf-single-section">
        {en(language) &&
          <>
          To share the spirit of “Family Foundation, Talent Growth” with more Chinese families and children.
          </>
        }
        {ch(language) &&
          <>
          把"家庭根基，天赋成长”的精神分享给更多的中国家庭与孩子。
          </>
        }
        </div>
      </Section>
      <Section title={ch(language) ? '产品介绍' : 'Products'}>
        <div className="bf-single-section">
        {en(language) &&
          <>
          The products of Quway focus on three themes: parent-child relationships, early childhood education, and joy. Based on the theme of parent-child relationships, products are designed for children aged 0-6 and their parents. Through the interaction between parents and children, the products help to enhance relationships. In terms of early childhood education, Quway is devoted to developing early education toys and books for children aged 0-6. The content includes subject based knowledge, behavior guidance, arts, handcrafts, and more.  Quway’s products are designed according to the developmental characteristics of preschool children, with adaptations at different ages.
          </>
        }
        {ch(language) &&
          <>
          趣威文化的产品由三个部分组成，分别为亲子、早教、趣味等三个方面组成，产品的实际内容与设计都围绕“亲子”为主题，主要针对0-6岁婴幼儿以及家长通过产品引导亲子互动，让消费者在使用产品的同时得以增进亲子关系。在早教方面，趣威文化专注于开发0-6岁学前幼儿发展所需的早教玩具和图书产品，内容涉及学科知识，行为引导，艺术培养，手工创造等方面，培养孩子学习知识的兴趣；趣威文化产品都是根据学前幼儿发展特征设计，满足不同年龄段的幼儿玩乐和学习。
          </>
        }
        </div>
      </Section>
      <Section title={ch(language) ? '产品系列' : 'Collection'}>
        <div className="bf-single-section">
        {en(language) &&
          <>
          The products of Quway Culture consist of the Audio series, Laima series, Handcrafting series, Intelligence Enlightenment series, Art series, etc. The principal products are “Quway Chinese Culture Learing Sound Book”, “Quway Child Learning Encyclopedia with Reading Pen”, “Chinese Pinyin Magic Learning Recording Book”, “Animal Sounds Book”, and “Transportation Sounds Book”, etc.
          </>
        }
        {ch(language) &&
          <>
          产品系列主要是有声系列、赖马系列、手工系列、启智系列、艺术系列等、主要产品有《趣威文化点读认知图鉴》、《趣威文化点读认知百科》、《汉语拼音录音魔法学习机》、《动物声音》、《交通声音》等。
          </>
        }
        </div>
      </Section>
      <Section title={ch(language) ? '针对人群' : 'Market Target'}>
        <div className="bf-single-section">
        {en(language) &&
          <>
          Family Parenting Course Series: All Parents
          <br/>
          Early Child Education Toy Series: Preschool Children Aged 0-6
          </>
        }
        {ch(language) &&
          <>
          家庭教养课程系列：所有年龄段、爱孩子的父母。
          <br/>
          儿童早教玩具系列： 适合0-6岁学龄前儿童。
          </>
        }
        </div>
      </Section>
      <Section title={ch(language) ? '服务内容' : 'Product Offerings'}>
        <div className="bf-single-section">
        {en(language) &&
          <>
          Quway focuses on providing parenting resources, early education audio materials, language learning guides, and parent-child communication skills and other products.
          </>
        }
        {ch(language) &&
          <>
          趣威文化主要提供丰富的育儿理念、音频早教资源、儿童语言学习启蒙、亲子沟通互动等服务内容。
          </>
        }
        </div>
      </Section>
      <Section title={ch(language) ? '最新产品' : 'Latest Products'}>
        <div className="bf-single-section">
        <div className="desktop">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={4}
              loop
              autoplay={false}>
              <img src={quway2} alt="img2"/>
              <img src={quway3} alt="img3"/>
              <img src={quway4} alt="img4"/>
              <img src={quway5} alt="img5"/>
              <img src={quway6} alt="img6"/>
              <img src={quway7} alt="img7"/>
              <img src={quway8} alt="img8"/>
              <img src={quway9} alt="img9"/>
              <img src={quway10} alt="img10"/>
              <img src={quway11} alt="img11"/>
              <img src={quway12} alt="img12"/>
              <img src={quway13} alt="img13"/>
              <img src={quway14} alt="img14"/>
              <img src={quway15} alt="img14"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="mobile">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={1}
              loop
              autoplay={false}>
              <img src={quway2} alt="img2"/>
              <img src={quway3} alt="img3"/>
              <img src={quway4} alt="img4"/>
              <img src={quway5} alt="img5"/>
              <img src={quway6} alt="img6"/>
              <img src={quway7} alt="img7"/>
              <img src={quway8} alt="img8"/>
              <img src={quway9} alt="img9"/>
              <img src={quway10} alt="img10"/>
              <img src={quway11} alt="img11"/>
              <img src={quway12} alt="img12"/>
              <img src={quway13} alt="img13"/>
              <img src={quway14} alt="img14"/>
              <img src={quway15} alt="img15"/>
            </OwlCarousel>
          </div>
        </div>
      </div>
      </Section>
      <BFFooter/>
    </div>
  );
}
