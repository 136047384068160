import React from 'react';
import './BFCard.scss';

export default function BFCard(props) {
  return (
    <div className="bf-card">
      <img className="bf-card-picture" src={props.picture} alt="image"/>
      <div className="bf-card-content">
        <div className="bf-card-title">{props.title}</div>
        <div className="bf-card-desc">{props.desc}</div>
      </div>
    </div>
  );
}
