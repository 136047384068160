import React, {useContext, useState} from 'react';
import emailjs from 'emailjs-com';
import LanguageContext from '../../context/language.context';
import {Label} from './Contact.const';
import './Contact.scss';

export default function Contact(props) {
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactReason, setContactReason] = useState('');

  const {language} = useContext(LanguageContext);

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_1pqbtft', 'template_l720vv5', e.target, 'user_cezqEZa6Hx5lyDbkF8s1V')
      .then((result) => {
        alert('Thank you!');
        setContactName('');
        setContactEmail('');
        setContactPhone('');
        setContactReason('');
      }, (error) => {
        alert('Sorry!');
      });
  }

  return (
    <form className="bf-form-block" onSubmit={sendEmail}>
      <div className="bf-form-row">
        <label htmlFor="contact_name">{Label[language].name}:</label>
        <input type="text" name="contact_name"
          value={contactName}
          onChange={e => setContactName(e.target.value)}
        />
      </div>
      <div className="bf-form-row">
        <label htmlFor="contact_email">{Label[language].email}:</label>
        <input type="text" name="contact_email"
          value={contactEmail}
          onChange={e => setContactEmail(e.target.value)}
          />
      </div>
      <div className="bf-form-row">
        <label htmlFor="contact_phone">{Label[language].phone}:</label>
        <input type="text" name="contact_phone"
          value={contactPhone}
          onChange={e => setContactPhone(e.target.value)}
        />
      </div>
      <div className="bf-form-row">
        <label htmlFor="contact_reason">{Label[language].question}:</label>
        <textarea name="contact_reason"
          value={contactReason}
          onChange={e => setContactReason(e.target.value)}
          />
      </div>
      <div className="bf-form-row">
        <label/>
        <input type="submit" value="Submit" className="btn btn-secondary"/>
      </div>
    </form>
  );
}
