import React from 'react';
import './Section.scss';

export default function Section(props) {
  const classes = props.classes ? ['section', ...props.classes] : ['section'];
  const titleClasses = ['section-title', props.underline ? 'underline' : ''];

  return (
    <div className={classes.join(' ')}>
      { props.title &&
        <div className={titleClasses.join(' ')}>{props.title}</div>
      }
      <div className="section-body">{props.children}</div>
    </div>
  );
}
