import {Link} from 'react-router-dom';
import logo from '../assets/image/bf-logo.png';
import React, {useContext, useState} from 'react';
import LanguageContext from '../context/language.context';
import {ch, en} from '../util/common';
import './TopNav.scss';

export default function TopNav() {
  const wechat = 'wechat: bellflowerca';
  const email = 'bellflowertradingcorp@gmail.com';

  const [isMenuOpen, setMenuOpen] = useState(false);
  const {language, setLanguage} = useContext(LanguageContext);

  const links = [
    {label: ch(language) ? '首页' : 'Home', to: '/'},
    {label: ch(language) ? '晨光文具' : 'M&G', to: '/MG'},
    {label: ch(language) ? '天文文具' : 'Tenwin', to: '/TenWin'},
    {label: ch(language) ? '趣威图书' : 'QuWay', to: '/QuWay'},
  ];

  return (
    <div className="topnav">
      <nav className="desktop">
        <div className="topnav-bar clearfix">
          <div className="topnav-contact">
            <i className="icon-bubbles3"/>{wechat}
          </div>
          <div className="topnav-email">
            <i className="icon-envelope-o"/>{email}
          </div>
          <div className="topnav-lang">
            <span className="topnav-lang-label">Language：</span>
            <span className={'topnav-lang-link ' + (language === 'ch' && 'active')} onClick={() => {setLanguage('ch')}}>中</span>
            <span className={'topnav-lang-link ' + (language === 'en' && 'active')} onClick={() => {setLanguage('en')}}>En</span>
          </div>
        </div>
      </nav>
      <nav className="mobile">
        <div className="topbar clearfix">
          <div className="topbar-menu-btn" onClick={() => setMenuOpen(!isMenuOpen)}>
            <span className="icon-menu2"/>
          </div>
          <div className="topbar-lang">
            <span className={'topbar-lang-link first ' + (language === 'ch' && 'active')} onClick={() => {setLanguage('ch')}}>中</span>
            <span className={'topbar-lang-link ' + (language === 'en' && 'active')} onClick={() => {setLanguage('en')}}>En</span>
          </div>
          <div className={['topbar-drop-menu'].concat(isMenuOpen ? ['active']: []).join(' ')}>
            {links.map((link) =>
              <Link key={link.label} className="topbar-drop-menu-item" to={link.to}>{link.label}</Link>
            )}
          </div>
        </div>
      </nav>
      <div className="topnav-menu clearfix">
        <Link to="/" className="topnav-logo-wrapper">
          <img className="topnav-logo-image" src={logo} alt="Bell Flower Trading" />
        </Link>
        <div className="topnav-menu-link-group">
          {links.map((link) =>
            <Link key={link.label} className="topnav-menu-link" to={link.to}>{link.label}</Link>
          )}
        </div>
      </div>
    </div>
  );
}
