import React, {useContext} from 'react';
import TopNav from '../TopNav/TopNav';
import Section from '../Section/Section';
import {
  About,
  Specialty,
  Logistics,
  Warehouse,
  Business,
  Contact as ContactLabel,
  Brands,
  MandG,
  Tenwin, Quway
} from './Home.const';
import './Home.scss';
import BFFooter from '../BFFooter/BFFooter';
import LanguageContext from '../context/language.context';
import BFLogoCard from '../BFLogoCard/BFLogoCard';
import {Link} from 'react-router-dom';
import BFCard from '../BFCard/BFCard';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Contact from './Contact/Contact';
import womanLiving from './woman-living.jpg';
import pencilMachine from './pencil_machine.jpg';

export default function Home() {
  const {language} = useContext(LanguageContext);

  return (
    <div className="home">
      <TopNav/>
      <div className="desktop">
        <div className="topnav-carousel">
          <OwlCarousel className="owl-theme topnav-owl-carousel-root"
            items={1}
            loop
            autoplay
            autoplayTimeout={5000}
            autoplayHoverPause>
            <img src={womanLiving}/>
            <img src={pencilMachine}/>
          </OwlCarousel>
        </div>
      </div>
      <div className="mobile">
        <img className="splash" src={womanLiving}/>
      </div>
      <Section title={About[language].title} underline={true} classes={['about']}>
        <div className="bf-single-section" dangerouslySetInnerHTML={{ __html: About[language].content }} />
      </Section>
      <Section>
        <div className="desktop">
          <div className="bf-dual-section text-first">
            <div className="bf-dual-desc">
              <div className="bf-dual-desc-box">
                <div className="bf-dual-desc-title">{Specialty[language].title}</div>
                <div className="bf-dual-desc-content">{Specialty[language].content}</div>
              </div>
            </div>
            <div className="bf-dual-picture">
              <img src={Specialty.imageUrl} alt="Speciality"/>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="bf-single-section">
            <BFCard
              picture={Specialty.imageUrl}
              title={Specialty[language].title}
              desc={Specialty[language].content}
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="desktop">
          <div className="bf-dual-section picture-first">
            <div className="bf-dual-picture">
              <img src={Logistics.imageUrl} alt="Logistics"/>
            </div>
            <div className="bf-dual-desc">
              <div className="bf-dual-desc-box">
                <div className="bf-dual-desc-title">{Logistics[language].title}</div>
                <div className="bf-dual-desc-content">{Logistics[language].content}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="bf-single-section">
            <BFCard
              picture={Logistics.imageUrl}
              title={Logistics[language].title}
              desc={Logistics[language].content}
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="desktop">
          <div className="bf-dual-section text-first">
            <div className="bf-dual-desc">
              <div className="bf-dual-desc-box">
                <div className="bf-dual-desc-title">{Warehouse[language].title}</div>
                <div className="bf-dual-desc-content">{Warehouse[language].content}</div>
              </div>
            </div>
            <div className="bf-dual-picture">
              <img src={Warehouse.imageUrl} alt="Warehouse"/>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="bf-single-section">
            <BFCard
              picture={Warehouse.imageUrl}
              title={Warehouse[language].title}
              desc={Warehouse[language].content}
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="desktop">
          <div className="bf-dual-section picture-first">
            <div className="bf-dual-picture">
              <img src={Business.imageUrl} alt="Business"/>
            </div>
            <div className="bf-dual-desc">
              <div className="bf-dual-desc-box">
                <div className="bf-dual-desc-title">{Business[language].title}</div>
                <div className="bf-dual-desc-content">{Business[language].content}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="bf-single-section">
            <BFCard
              picture={Business.imageUrl}
              title={Business[language].title}
              desc={Business[language].content}
            />
          </div>
        </div>
      </Section>
      <Section classes={['brands']} title={Brands[language].title} underline={true}>
        <div className="desktop">
          <div className="bf-triple-section">
            <div className="bf-col">
              <Link to="/chan-xin">
                <BFLogoCard
                  image={MandG[language].imageUrl}
                  title={MandG[language].title}
                  desc={MandG[language].content}
                />
              </Link>
            </div>
            <div className="bf-col">
              <Link to="/tian-wen">
                <BFLogoCard
                  image={Tenwin[language].imageUrl}
                  title={Tenwin[language].title}
                  desc={Tenwin[language].content}
                />
              </Link>
            </div>
            <div className="bf-col">
              <Link to="/qiu-wai">
                <BFLogoCard
                  image={Quway[language].imageUrl}
                  title={Quway[language].title}
                  desc={Quway[language].content}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="bf-single-section">
            <Link to="/chan-xin">
              <BFLogoCard
                image={MandG[language].imageUrl}
                title={MandG[language].title}
                desc={MandG[language].content}
              />
            </Link>
            <Link to="/tian-wen">
              <BFLogoCard
                image={Tenwin[language].imageUrl}
                title={Tenwin[language].title}
                desc={Tenwin[language].content}
              />
            </Link>
            <Link to="/qiu-wai">
              <BFLogoCard
                image={Quway[language].imageUrl}
                title={Quway[language].title}
                desc={Quway[language].content}
              />
            </Link>
          </div>
        </div>
      </Section>
      <Section title={ContactLabel[language].title}>
        <Contact/>
      </Section>
      <BFFooter/>
    </div>
  );
}
