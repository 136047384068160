import React from 'react';
import {useContext} from 'react';
import {ch, en} from '../util/common';
import LanguageContext from '../context/language.context';
import TopNav from '../TopNav/TopNav';
import Section from '../Section/Section';
import BFFooter from '../BFFooter/BFFooter';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './TenWin.scss';

import tenwin1 from '../assets/image/tenwin/WeChat Image_20210219091110.png';
import tenwin2 from '../assets/image/tenwin/WeChat Image_20210219091140.png';
import tenwin4 from '../assets/image/tenwin/WeChat Image_20210219091202.png';
import tenwin5 from '../assets/image/tenwin/WeChat Image_20210219091209.png';
import tenwin6 from '../assets/image/tenwin/WeChat Image_20210219091220.png';
import tenwin7 from '../assets/image/tenwin/WeChat Image_20210219091228.png';

export default function TenWin() {
  const {language} = useContext(LanguageContext);

  return (
    <div className="tenwin">
      <TopNav/>
      <div className="logo-title">
        <img className="logo-image" src="images/TenWin.jpg"/> <span className="title-text">{ch(language) ? '公司介紹' : 'Introduction'}</span>
      </div>
      <div className="bf-single-section">
        {en(language) &&
          <>
          Tenwin is a stationery brand launched by Ningbo Tianhong Stationery Co., Ltd. Tenwin is engaged in innovative and technological electric stationery products. They aim to improve the consumer experience with high quality and highly efficient products. Tenwin promotes electric pencil sharpeners, electric erasers, electric desktop vacuum cleaners and other electric stationary products. In the future, Tenwin will extend its product lines to electric office tools and creative electric stationery products.
          <br/><br/>
          The Tenwin Stationery brand was established in 2014 and in 2016, Tenwin entered Tmall’s exclusive list of brands. Ningbo Tianhong Stationery Co., Ltd. , established in 1989, has its own R&D team and manufacturing factory, they have focused on developing electric pencil sharpeners for more than ten years. The sales volume of Tenwin’s electric pencil sharpeners exceeds other similar products, making them the best-selling electric pencil sharpener brand in China. Their outstanding technology and quality has achieved them a leading position in the field of electric stationery. Tenwin’s products are widely sold in more than 30 countries including the United States, Southeast Asia, and Japan.
          <br/><br/>
          The Tenwin 8009 Automatic Electric Pencil Sharpener won the 2018 German Red Dot Design Award. This sharpener is lithium battery charged with auto sharpening and stopping. In addition, the sharpening helical blade can be replaced to extend a sharpener’s lifespan.  This sharpener meets all different pencil sharpening requirements for art students, making it the top choice for artists.
          <br/><br/>
          In addition to the professional use for art students, Tenwin has launched a series of multi-functional electric pencil sharpeners for elementary students, making pencil sharpening for children an ease. Since its launch, it has been well loved by parents and by word of mouth, this series has attracted a large customer base.
          <br/><br/>
          In the future, Tenwin will further invest in team expansion and attracting talent. Thus, also promoting the positive reputation of Tenwin.
          </>
        }
        {ch(language) &&
          <>
          天文，是宁波天虹文具有限公司推出的文具品牌，主营创新、科技的电动文具产品，以追求高品质高效率用户为对象，主推电动削笔机、电动橡皮擦、电动桌面吸尘器等电动文具系列产品，以后会延伸到电动办公工具、电动创意文具产品中，给用户带来更便捷更完美的体验。
          <br/><br/>
          2014年，天文文具品牌在国内成立，2016年”tenwin天文”入住天猫品牌库，电动削笔机销量遥遥领先，宁波天虹文具有限公司拥有自己的研发团队和生产工厂，专注研发电动削笔机十余年，凭借其领先的技术和高品质成就了其电动文具领域当仁不让的地位，同时也成为中国电动削笔机最畅销的文具品牌，产品广销美国、东南亚、日本等30多个国家。
          <br/><br/>
          天文8009电动削笔机还荣获2018德国红点设计奖，这款削笔机在设计方面追求实用便利，具有锂电池充电、大容量废屑槽、削尖立停等功能，而且削笔机的刀架可以更换，能够延长使用寿命。对于美术绘画生来说，可谓是削笔利器，能够满足绘画生对绘画铅笔的削笔要求，成为美术生的首选产品。
          <br/><br/>
          除了美术绘画专业电动削笔机，天文还推出一系列小学生多功能的电动削笔机，孩子省力，妈妈省心，上市以来，收获了大批妈妈们的喜爱，令人感动的是，很多妈妈使用后主动分享给其他的用户，帮助我们吸引了大批的客户。
          <br/><br/>
          未来，天文的创作团队会在团队扩充和人才引进中投入一定的精力，同时加大对天文品牌的推广，让更多的客户能够知道和使用天文的产品。
          </>
        }
      </div>
      <Section title={ch(language) ? '最新产品' : 'Latest Products'}>
        <div className="bf-single-section">
        <div className="desktop">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={4}
              loop
              autoplay={false}>
              <img src={tenwin1} alt="tenwin1"/>
              <img src={tenwin2} alt="tenwin2"/>
              <img src={tenwin4} alt="tenwin4"/>
              <img src={tenwin5} alt="tenwin5"/>
              <img src={tenwin6} alt="tenwin6"/>
              <img src={tenwin7} alt="tenwin7"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="mobile">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={1}
              loop
              autoplay={false}>
              <img src={tenwin1} alt="tenwin1"/>
              <img src={tenwin2} alt="tenwin2"/>
              <img src={tenwin4} alt="tenwin4"/>
              <img src={tenwin5} alt="tenwin5"/>
              <img src={tenwin6} alt="tenwin6"/>
              <img src={tenwin7} alt="tenwin7"/>
            </OwlCarousel>
          </div>
        </div>
      </div>
      </Section>
      <BFFooter/>
    </div>
  );
}
