import React, {useContext} from 'react';
import './BFLogoCard.scss';
import {en} from '../util/common';
import LanguageContext from '../context/language.context';

export default function BFLogoCard(props) {
  const {language} = useContext(LanguageContext);

  return (
    <div className="bf-logo-card">
      <div className="bf-logo-card-image">
        <img src={props.image} alt={props.image}/>
      </div>
      <div className={['bf-logo-card-desc', en(language) ? 'min-height-required': ''].join(' ')}>
        {props.desc}
      </div>
    </div>
  );
}
