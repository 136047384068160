import React from 'react';
import './BFFooter.scss';

export default function BFFooter() {
  return (
    <div className="footer">
      Copyright &copy; Bellflower Trading Corp. All rights reserved.
    </div>
  )
};
