export const About = {
  en: {title:'About Us', content: 'Bellflower Trading Corp. was established in 2020 and is located in Vancouver, British Columbia. We specialize in import and export between China and Canada, supplying both local Canadian retailers and online platforms. Bellflower Trading Corp. aims to provide clients a more convenient, efficient, and ideal experience. Our vision is to collaborate with various sales channels in the Canadian market and grow and develop together.<br/><br/>Bellflower Trading Corp. selects premium products with cutting edge technology. We cooperate with well-known and reputable Chinese brands. The products we represent are all independently designed and developed in China. Many have won international design awards such as the iF Product Design Award, Red Dot Design Award, and Japanese G-Mark Award. Our products come from an innovative direction where they are researched, designed, and developed in China. Not simply manufactured in China but created in China. We are committed to pushing these creations to the international stage.'},
  ch: {title:'关于我们', content: 'Bellflower Trading Corp. 成立于2020年，坐落在加拿大温哥华。专注于中加贸易进出口，臻选具有科技含量的高品质产品，供货加拿大本地零售商及网络平台。为用户提供更便捷、更高效、更完美的服务是我们的宗旨。与面对加拿大本地消费者的各种销售模式的客户合作共赢、共同发展是我们的愿景。<br/><br/>本公司精选中国知名品牌合作，代理的产品均为中国自主设计、自主研发。部分产品曾多次斩获国际设计大奖—如德国iF奖、红点奖和日本G-mark大奖。开创了一条中国自主研发的创新之路，完成从中国制造向中国创造的华丽蜕变。致力于把中国创造推上国际舞台，真正实现“货通天下”。'}
};
export const Specialty = {
  imageUrl: 'images/specialty.jpg',
  en: {title:'Our Specialty', content:'Customer satisfaction is our aim. All products imported by Bellflower Trading Corp. have been selected with a guaranteed quality.  Each product has undergone long-term testing in the global market, and their sales volume is well above other similar products. Bellflower Trading Corp. maintains a long-term and stable cooperative relationship with brand owners and is an exclusive sales agent for these brands in the Canadian market. Bellflower Trading Corp. is able to provide the best-selling products with the latest technology to Canadian customers. We solemnly commit to serving a diversified client base to meet individual needs. We tailor the supply chain based on the needs of online e-commerce platforms and local retail stores. Bellflower Trading Corp. also conducts pre-sale consultation, post-sale training, and technical support for our buyers. Any product related issues are dealt with in a timely manner and customer satisfaction is guaranteed.'},
  ch: {title:'我们的专业', content:'客户的满意度是我们追求的目标。所有产品都经过臻选，品质绝对保证。每款产品都经过长时间的市场检验，在同类产品中销量摇摇领先。本公司与品牌商保持长期稳定的合作关系，拥有加拿大独家代理，第一时间为加拿大客户提供畅销的和最新技术的产品。本公司在此郑重承诺，为多样化客户群提供优质服务，满足个性化需求。根据线上电商平台和线下门店的特性，量身定做供应链。同时会对客户进行产品的售前咨询和售后培训工作，给采购商提供相应的技术支持。任何产品如有质量问题，及时处理，售后保障完善，做到让零售商进货无忧。'}
};
export const Logistics = {
  imageUrl: 'images/logistics.jpg',
  en: {title: 'Our Logistics', content: 'Bellflower Trading Corp. has efficient and stable import channels and partners which can ensure seamless connection of transportation, customs declaration, and customs clearance. Despite the shortage of cargo ships, our company can still maintain an efficient purchase process and ensure sufficient inventory reserves. By providing stable inventory turnover to Canadian market, Bellflower Trading Corp. is ready to meet a wide range of needs.'},
  ch: {title: '我们的物流', content: '本公司拥有高效、稳定的进口渠道及团队合作伙伴，运输、通关、报关、清关无缝衔接，一切无忧。在集装箱、装箱货轮紧缺的大环境下，依然能保持高效、迅速的进货流程。时刻保障充足的库存储备。为加拿大本地零售商提供稳定的库存周转，随时配合线上线下的多样化订货需求。'}
};
export const Warehouse = {
  imageUrl: 'images/warehouse.jpg',
  en: {title: 'Our Warehouse', content: 'Bellflower Trading Corp. has a large-capacity warehouse and insures sufficient inventory of products with high demand and high turnover rates. Professional management provides highly efficient operational control to ensure all orders arrive on time. This is a part of our outstanding customer service which meets all individual needs.'},
  ch: {title: '我们的仓储', content: '本公司拥有大容量仓库，需求量高、周转率高的产品时刻保持充足库存。专业人士管理，提供高水平的质量、功能和运营控制，确保订单及时、准确到达，并与我们出众的客户服务和专门定制的灵活解决方案融为一体。'}
};
export const Business = {
  imageUrl: 'images/partnership.jpg',
  en: {title: 'Business Cooperation', content: 'With our own expertise and years of market operation experience, Bellflower Trading Corp. can provide clients with relevant product recommendations and formulate ordering plans for their market positioning. Bellflower Trading Corp. not only meets the diversified ordering needs of online e-commerce and physical retail stores, but also provides product customization services. Our brand manufacturer has 350 acres of industrial area and invests more than 100 million Yuan in product research, technology design, and development every year. With more than 700 patents, we can provide customers with the best product plan at the most affordable prices.'},
  ch: {title: '商业合作', content: '本公司凭借自己的专业知识和多年的市场经验，可以为客户的市场定位提供相关产品建议，制定订货方案。本公司在满足线上电商、线下实体零售的多样化订货需求的同时，提供产品定制服务。品牌商拥有350亩工业园区，每年公司投入超过一个亿人民币用于产品和技术的设计研发，拥有专利超过700项，能提供给客户一套最佳的产品方案及最实惠的价格。'},
};
export const Brands = {
  en: {title: 'Brands'},
  ch: {title: '代理品牌'}
};
export const Contact = {
  en: {title: 'Contact Us'},
  ch: {title: '联络我们'}
};
export const MandG = {
  en: {title: 'M&G', content: 'Established in 1989, M&G is one of the world’s largest stationery manufacturers. M&G is an integrated stationery supplier that provides creative value and extraordinary services and is committed to make learning and working a better experience. Products include writing instruments, student stationery and other related products. M&G was officially listed on the Shanghai Stock Exchange in 2015.', imageUrl: 'images/MG.jpg'},
  ch: {title: '晨光', content: '成立于1989年，全球最大的文具制造商之一，是一家整合创意价值与服务优势的综合文具供应商，致力于让学习和工作更快乐、更高效。产品涵盖书写工具、学生文具、办公文具及其他相关产品。公司于2015年正式在上交所挂牌上市。', imageUrl: 'images/MG.jpg'}
};
export const Tenwin = {
  en: {title: 'Tenwin', content: 'Tenwin has focused on technology development for 30 years, and its products have won the German Red Dot Design Award in 2018 and 2020. Tenwin is a representative of a new generation of high-tech stationery. Their products have been exported to more than 30 countries and regions in the US, Japan, Korea, Europe, the Middle East, and Southeast Asia. The sales volume of their electric pencil sharpener exceeded 2 million in 2018.', imageUrl: 'images/TenWin.jpg'},
  ch: {title: '天文', content: '致力于技术开发三十年，旗下产品获得2018，2020德国红点奖，新一代高科技文具的代表。产品已经远销至美国、日本、韩国、欧洲、中东及东南亚30多个 国家和地区，截止2018年，单电动削笔机销量已突破200万只。', imageUrl: 'images/TenWin.jpg'},
};
export const Quway = {
  en: {title: 'Quway', content: 'Quway focuses on the development of early education toys and book products for 0-6-year-old preschool children. Their products lines include an audio series, Laima series, handcraft series, educational series, and art series. Quway’s AI reading pen has been a top seller in the Chinese market for many years, and it can read nearly 2,000 Chinese and English books. Quway is committed to becoming the best reading pen brand in China.', imageUrl: 'images/QuWay.jpg'},
  ch: {title: '趣威', content: '专注开发0-6岁学前幼儿发展所需的早教玩具和图书产品，产品系列主要是有声系列、赖马系列、手工系列、启智系列、艺术系列等。旗下的AI点读笔畅销中国多年，能点读将近2000本中英文图书，致力成为中国最好的点读笔品牌。', imageUrl: 'images/QuWay.jpg'},
};
