import React, {useContext} from 'react';
import TopNav from '../TopNav/TopNav';
import './MG.scss';
import BFFooter from '../BFFooter/BFFooter';
import LanguageContext from '../context/language.context';
import Section from '../Section/Section';
import {ch, en} from '../util/common';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import arts1 from '../assets/image/mg/arts/晨光MGARTS双头细杆马克笔（3）ZPMV0701.jpg';
import arts2 from '../assets/image/mg/arts/晨光MGKIDS软头马克笔24色ZPMV8002（长图1).jpg';
import arts3 from '../assets/image/mg/arts/晨光丙烯彩绘笔ZPLN6571（4）.jpg';
import arts4 from '../assets/image/mg/arts/晨光儿童优握粗杆油画棒ZGM91158（2).jpg';
import arts5 from '../assets/image/mg/arts/晨光元气果果彩绘棒48色（2）AGMV1214.jpg';
import arts6 from '../assets/image/mg/arts/晨光印章水彩笔36色ACP91250（1）封面.jpg';
import arts7 from '../assets/image/mg/arts/晨光双头马克笔108色 APMV0907(6）.jpg';
import arts8 from '../assets/image/mg/arts/晨光双线轮廓笔ZPMV2505（3）.jpg';
import arts9 from '../assets/image/mg/arts/晨光固体颜料12色 APLN6587（3）.jpg';
import arts10 from '../assets/image/mg/arts/晨光固体颜料12色 APLN6587（长图4）.jpg';
import arts11 from '../assets/image/mg/arts/晨光小狐希里12色可水洗水彩笔ACPN0275(1）.jpg';
import arts12 from '../assets/image/mg/arts/晨光小狐希里48色可水洗水彩笔ACPN0275(1)封面.jpg';
import arts13 from '../assets/image/mg/arts/晨光无木可擦彩色铅笔24色AWPQ0508(2)封面.jpg';
import arts14 from '../assets/image/mg/arts/晨光无木可擦彩色铅笔48色AWPQ0510(1）封面.jpg';
import arts15 from '../assets/image/mg/arts/晨光无木可擦彩色铅笔48色AWPQ0510(4).jpg';
import arts16 from '../assets/image/mg/arts/晨光油画棒KIDS子弹头易可洗36色ZGM91153（4) copy.jpg';
import arts17 from '../assets/image/mg/arts/晨光盒装水溶性彩色铅笔48色（长图1）AWPQ1904.jpg';
import arts18 from '../assets/image/mg/arts/晨光筒装彩色铅笔48色AWP36828(2）封面.jpg';
import arts19 from '../assets/image/mg/arts/晨光组合画笔5支装ABH978B8（1）封面.jpg';
import arts20 from '../assets/image/mg/arts/晨光美术勾线笔ACP95890（6）.jpg';
import arts21 from '../assets/image/mg/arts/晨光闪亮彩色啫喱笔八色AGP13174（2）.jpg';
import arts22 from '../assets/image/mg/arts/晨光食品级可水洗软头水彩笔（2）48色.jpg';
import arts23 from '../assets/image/mg/arts/晨光食品级可水洗软头水彩笔（长图1）12色.jpg';

import children1 from '../assets/image/mg/children/晨光36K拼音本等（长图1）K36182.jpg'
import children2 from '../assets/image/mg/children/晨光HB洞洞铅笔AWP30455（1）封面.jpg'
import children3 from '../assets/image/mg/children/晨光九行二合一学具（4）.jpg'
import children4 from '../assets/image/mg/children/晨光优握28天养成记握笔器APJ99210（4）.jpg'
import children5 from '../assets/image/mg/children/晨光牛皮纸拼音田字写字簿15T76（长图2）.jpg'
import children6 from '../assets/image/mg/children/晨光简系列阅读架（3）.jpg'

import office1 from '../assets/image/mg/office/晨光4B白橡皮（大号）AXPN0759（2）.jpg';
import office2 from '../assets/image/mg/office/晨光12块组合装橡皮擦AXPN0787（4）.jpg';
import office3 from '../assets/image/mg/office/晨光K35三色中性笔（长图3）.jpg';
import office4 from '../assets/image/mg/office/晨光原木铅笔50支装HB AWP30411（长图1）.jpg';
import office5 from '../assets/image/mg/office/晨光双面胶AJD97348（4）.jpg';
import office6 from '../assets/image/mg/office/晨光喵屋喵力星球系列橡皮（四块）AXP963H5（2）.jpg';
import office7 from '../assets/image/mg/office/晨光易擦白板笔AWMY2271（长图1）.jpg';
import office8 from '../assets/image/mg/office/晨光本味六色中性笔（2）AGPH2805.jpg';
import office9 from '../assets/image/mg/office/晨光本味系列直液式ARPM2402（5）.jpg';
import office10 from '../assets/image/mg/office/晨光樱花修正带ACT53805（2）.jpg';
import office11 from '../assets/image/mg/office/晨光樱花限定套装HAGP1253 (3）.jpg';
import office12 from '../assets/image/mg/office/晨光点点胶AJD73203（长图7-1）.jpg';
import office13 from '../assets/image/mg/office/晨光点点胶AJD73203（长图7-2）.jpg';
import office14 from '../assets/image/mg/office/晨光热可擦中性笔AKPA0110（长图9）.jpg';
import office15 from '../assets/image/mg/office/晨光璀璨星辰中性笔0.5mm AGP67128（2）.jpg';
import office16 from '../assets/image/mg/office/晨光白板擦ASC99364（3）封面.jpg';
import office17 from '../assets/image/mg/office/晨光米菲金属秀秀笔FWBT0402（5）.jpg';
import office18 from '../assets/image/mg/office/晨光速干签字笔ARP57501（长图4）.jpg';
import office19 from '../assets/image/mg/office/晨光金色长尾票夹（5）ABS916C1F9.jpg';
import office20 from '../assets/image/mg/office/晨光高粘度固体胶（长图1） copy.jpg';

export default function MG() {
  const {language} = useContext(LanguageContext);

  return (
    <div className="mg">
      <TopNav/>
      <div className="logo-title">
        <img className="logo-image" src="images/MG.jpg" alt="M&G"/> <span className="title-text">{ch(language) ? '公司介紹' : 'Introduction'}</span>
      </div>
      <div className="bf-single-section">
        {en(language) &&
          <>
            As one of the world’s largest stationery manufacturers, M&G Stationery is an integrated stationery supplier which combines creativity and premium customer service. They are committed to enriching the user’s learning and working experience. Their products include writing instruments, student stationery, office stationery and other related products. M&G was officially listed on the Shanghai Stock Exchange in 2015 with the stock code 603899.
            <br/><br/>
            M&G Stationery has nearly 80,000 retail terminals across mainland China and has 440 retail stores such as M&G Shop and M&G Life. Thousands of new products are launched every year based on market demand, and the products are exported to over 50 countries and regions to partners all over the world. M&G has maintained double-digit growth in the last ten years, with a total revenue of 11.141 billion Yuan in 2019, an increase of 30.53% from 2018.
            <br/><br/>
            M&G’s headquarters are located in Shanghai, China with a 57 acre manufacturing area named the M&G Stationery Industrial Park. This Industrial Park is next to the China Pen Industry Base, National Industrial Design Centre, and China Light Industry Pen Engineering Technology Laboratory. In 2019, the M&G Stationery Laboratory was officially accredited by the China National Accreditation Service for Conformity Assessment (CNAS). CNAS’s testing capabilities are inline with international standards.
            <br/><br/>
            Every year, M&G Stationery invests over 100 million Yuan in the design and development of new products and technologies, they have nearly 800 patents so far. A newly invented product is able to be present in all retail terminals across China within 7 days. M&G Stationery’s products have won the Golden Pin Design Award, Red Star Design Award, and renowned international design awards such as the iF Design Award, Red Dot Design Award, and Japan Good Design Award.
            <br/><br/>
            As a national brand loved by Chinese consumers, M&G Stationery is pioneering a path of innovation in China and achieving the transformation from ‘made in China’ into ‘created in China’. In recent years, various products from M&G have been used at the APEC Conference, Boao Forum for Asia, Global CEO Conference and other national level conferences. M&G aims to build a ‘world-class stationery brand’.
          </>
        }
        {ch(language) &&
          <>
            作为全球最大的文具制造商之一，晨光文具是一家整合创意价值与服务优势的综合文具供应商，致力于让学习和工作更快乐、更高效。产品涵盖书写工具、学生文具、办公文具及其他相关产品。公司于2015年正式在上交所挂牌上市，股票代码603899。
            <br/><br/>
            晨光文具在全国覆盖近8万家零售终端，拥有晨光生活馆、九木杂物社等440家零售大店。同时基于市场需求每年推出上千款新品，产品远销50多个国家和地区，合作伙伴遍及全球。公司在过去十余年持续保持两位数的快速增长，2019年总营收111.41亿人民币，同比增长30.53%。
            <br/><br/>
            公司总部位于中国上海，拥有总占地面积达350亩的工业园区——晨光文具工业园。中国制笔工业基地、中国制笔中心、国家级工业设计中心，以及中国轻工业制笔工程技术重点实验室均落户于此。2019年晨光文具实验室正式获得中国合格评定国家认可委员会（CNAS）认可，检测能力与世界接轨。
            <br/><br/>
            每年公司投入超过1亿人民币用于产品和技术的设计研发，生产线上的新品只需7天就能呈现在全国的所有零售终端，目前已拥有专利近800项。晨光文具多次获得金点设计奖、红星奖等重量级设计类奖项，多款产品斩获国际设计大奖—德国iF奖及红点奖以及日本G-mark大奖，彰显了晨光文具国内一流的设计实力。
            <br/><br/>
            作为深受中国消费者喜爱的民族品牌，晨光正在开创一条中国文具的创新之路，完成从中国制造向中国创造的华丽蜕变。近年来，APEC会议、博鳌亚洲论坛、全球CEO大会等国家级高端会议现场均可见到晨光文具的各类产品，展现出晨光打造“世界级文具品牌”的愿景。
          </>
        }
      </div>
      <Section title={ch(language) ? '最新产品' : 'Latest Products'}>
        <div className="bf-single-section">
        <div className="desktop">
          <div className="subtitle">{ch(language) ? '办公用品': 'Office Supplies'}</div>
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={4}
              loop
              autoplay={false}>
              <img src={office1} alt="office1"/>
              <img src={office2} alt="office2"/>
              <img src={office3} alt="office3"/>
              <img src={office4} alt="office4"/>
              <img src={office5} alt="office5"/>
              <img src={office6} alt="office6"/>
              <img src={office7} alt="office7"/>
              <img src={office8} alt="office8"/>
              <img src={office9} alt="office9"/>
              <img src={office10} alt="office10"/>
              <img src={office11} alt="office11"/>
              <img src={office12} alt="office12"/>
              <img src={office13} alt="office13"/>
              <img src={office14} alt="office14"/>
              <img src={office15} alt="office15"/>
              <img src={office16} alt="office16"/>
              <img src={office17} alt="office17"/>
              <img src={office18} alt="office18"/>
              <img src={office19} alt="office19"/>
              <img src={office20} alt="office20"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="mobile">
          <div className="subtitle">{ch(language) ? '办公用品': 'Office Supplies'}</div>
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={1}
              loop
              autoplay={false}>
              <img src={office1} alt="office1"/>
              <img src={office2} alt="office2"/>
              <img src={office3} alt="office3"/>
              <img src={office4} alt="office4"/>
              <img src={office5} alt="office5"/>
              <img src={office6} alt="office6"/>
              <img src={office7} alt="office7"/>
              <img src={office8} alt="office8"/>
              <img src={office9} alt="office9"/>
              <img src={office10} alt="office10"/>
              <img src={office11} alt="office11"/>
              <img src={office12} alt="office12"/>
              <img src={office13} alt="office13"/>
              <img src={office14} alt="office14"/>
              <img src={office15} alt="office15"/>
              <img src={office16} alt="office16"/>
              <img src={office17} alt="office17"/>
              <img src={office18} alt="office18"/>
              <img src={office19} alt="office19"/>
              <img src={office20} alt="office20"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="subtitle">{ch(language) ? '美术用品' : 'Arts Supplies'}</div>
        <div className="desktop">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={4}
              loop
              autoplay={false}>
              <img src={arts1} alt="arts1"/>
              <img src={arts2} alt="arts2"/>
              <img src={arts3} alt="arts3"/>
              <img src={arts4} alt="arts4"/>
              <img src={arts5} alt="arts5"/>
              <img src={arts6} alt="arts6"/>
              <img src={arts7} alt="arts7"/>
              <img src={arts8} alt="arts8"/>
              <img src={arts9} alt="arts9"/>
              <img src={arts10} alt="arts10"/>
              <img src={arts11} alt="arts11"/>
              <img src={arts12} alt="arts12"/>
              <img src={arts13} alt="arts13"/>
              <img src={arts14} alt="arts14"/>
              <img src={arts15} alt="arts15"/>
              <img src={arts16} alt="arts16"/>
              <img src={arts17} alt="arts17"/>
              <img src={arts18} alt="arts18"/>
              <img src={arts19} alt="arts19"/>
              <img src={arts20} alt="arts20"/>
              <img src={arts21} alt="arts21"/>
              <img src={arts22} alt="arts22"/>
              <img src={arts23} alt="arts23"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="mobile">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={1}
              loop
              autoplay={false}>
              <img src={arts1} alt="arts1"/>
              <img src={arts2} alt="arts2"/>
              <img src={arts3} alt="arts3"/>
              <img src={arts4} alt="arts4"/>
              <img src={arts5} alt="arts5"/>
              <img src={arts6} alt="arts6"/>
              <img src={arts7} alt="arts7"/>
              <img src={arts8} alt="arts8"/>
              <img src={arts9} alt="arts9"/>
              <img src={arts10} alt="arts10"/>
              <img src={arts11} alt="arts11"/>
              <img src={arts12} alt="arts12"/>
              <img src={arts13} alt="arts13"/>
              <img src={arts14} alt="arts14"/>
              <img src={arts15} alt="arts15"/>
              <img src={arts16} alt="arts16"/>
              <img src={arts17} alt="arts17"/>
              <img src={arts18} alt="arts18"/>
              <img src={arts19} alt="arts19"/>
              <img src={arts20} alt="arts20"/>
              <img src={arts21} alt="arts21"/>
              <img src={arts22} alt="arts22"/>
              <img src={arts23} alt="arts23"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="subtitle">{ch(language) ? '儿童文具' : 'Children Supplies'}</div>
        <div className="desktop">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={4}
              loop
              autoplay={false}>
              <img src={children1} alt="children1"/>
              <img src={children2} alt="children2"/>
              <img src={children3} alt="children3"/>
              <img src={children4} alt="children4"/>
              <img src={children5} alt="children5"/>
              <img src={children6} alt="children6"/>
            </OwlCarousel>
          </div>
        </div>
        <div className="mobile">
          <div className="carousel-container">
            <OwlCarousel className="owl-theme"
              items={1}
              loop
              autoplay={false}>
              <img src={children1} alt="children1"/>
              <img src={children2} alt="children2"/>
              <img src={children3} alt="children3"/>
              <img src={children4} alt="children4"/>
              <img src={children5} alt="children5"/>
              <img src={children6} alt="children6"/>
            </OwlCarousel>
          </div>
        </div>
        </div>
      </Section>
      <BFFooter/>
    </div>
  )
}
