import React, {useState} from 'react';
import './custom.scss';
import './App.scss';
import LanguageContext from './context/language.context';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Home from './Home/Home';
import MG from './MG/MG';
import QuWay from './QuWay/QuWay';
import TenWin from './Tenwin/TenWin';

function App() {
  const [language, setLanguage] = useState('en');

  return (
    <LanguageContext.Provider value={{language,setLanguage}}>
      <Router>
        <div className="app">
          <Switch>
            <Route path="/MG">
              <MG />
            </Route>
            <Route path="/QuWay">
              <QuWay />
            </Route>
            <Route path="/TenWin">
              <TenWin />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </LanguageContext.Provider>
  );
}

export default App;
